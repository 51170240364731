import React, { useLayoutEffect, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import ReactGA from "react-ga"

import MyobLogo from "../images/myob-logo.svg"
import XeroLogo from "../images/xero-logo.svg"
import QboLogo from "../images/qbo-logo.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Trusts from "../components/trusts"
import Security from "../components/security"

import About01Image from "../images/about01.svg"
import About02Image from "../images/about02.svg"
import About03Image from "../images/about03.svg"

import CardImage1 from "../images/card1.svg"
import CardImage2 from "../images/card2.svg"
import CardImage3 from "../images/card3.svg"
import CardImage4 from "../images/card4.svg"
import playImage from "../images/new-landing/play.svg"
import lineArrowImage from "../images/line-arrow.svg"
import newVideoImage from "../images/new-video.svg"
import Modali, { useModali } from "../components/modal"
import Logo from "../images/logo.svg"
import btnVectorImage from "../images/btn-right-img.svg"
import btnVectorArrowImage from "../images/btn-right-arrow.svg"
import featureTopLineImage from "../images/feature/feature-top-line.svg"
import f1Image from "../images/feature/f1.png"
import f2Image from "../images/feature/f2.png"
import f3Image from "../images/feature/f3.png"
import f4Image from "../images/feature/f4.png"
import iconBGImage from "../images/feature/iconBG.svg"
import titleBottomVectorImage from "../images/feature/title-bottom-vector.svg"
import titleBottomVectorMobImage from "../images/feature/title-bottom-vector-mobile.svg"
import headerRightCornerImage from "../images/feature/header-right-corner.svg"
import leftImage from "../images/cashflow/left-img.png"
import arrowIcon from "../images/cashflow/arrow.svg"
import leftVectorIcon from "../images/join/left-vector.svg"
import starIcon from "../images/join/star.svg"
import joinLeftImage1 from "../images/join/left-img1.png"
import joinLeftImage2 from "../images/join/left-img2.png"
import getStartedBtn from "../images/join/get-started.png"
import joinRightImage1 from "../images/join/right-img1.svg"
import signUpStep from "../images/signup-step.svg"
import rightVector from "../images/right-vector.svg"
import stepHand from "../images/step-hand.svg"
import stepHandMob from "../images/step-hand-mob.svg"
import rightWhiteArrow from "../images/right-white-arrow.svg"
import valueLeftVector from "../images/value/left-vector.svg"
import starGroups from "../images/value/star-groups.svg"
import fan1Image from "../images/stories/cs33.jpg"
import fan2Image from "../images/value/fan2.png"
import fan3Image from "../images/stories/cs22.jpg"
import fan4Image from "../images/stories/cs11.jpg"
import fan5Image from "../images/stories/cs4.jpg"
import trustImage from "../images/trust-img.png"
import lucaplusFreeBgImage from "../images/lucaplus-free-bg.png"
import lucaplusFreeBgMobImage from "../images/lucaplus-free-bg-mob.png"
import btnLeftVectorImage from "../images/btn-left-vector.svg"
import btnRightVectorImage from "../images/btn-right-vector.svg"

import {
  bottomConfig,
  cardConfig,
  priceConfig,
  aboutConfig,
  featuresConfig,
  joinConfig,
  cashflowConfig,
} from "../constants/animation"
import { getStartUrl } from "../constants/url"

import Partners from "../components/partners"
import Hero from "../components/hero"
import Savings from "../components/savings"
import Cta from "../components/home/cta"
import videoImage from "../images/new-landing/video.png"
import Popup, { useModal } from "../components/popup"
import { GetCode1 } from "../components/getcode"
import { initializeAnalytics } from "../utils"
import { Link } from "gatsby"
import { Carousel } from "react-responsive-carousel"
import footerLeftVectorImg from "../images/footer-left-vector.svg"
import footerRightVectorImg from "../images/footer-right-vector.svg"

const IndexPage = () => {
  const [contactModal, toggleContactModal] = useModali()
  const [videoModal, toggleVideoModal] = useModali()
  const [videoModal1, toggleVideoModal1] = useModali()
  const [activeTab, setActiveTab] = useState("2")
  const [cookies, setCookie] = useCookies(["mainPageFooterBanner"])
  const [confirmationModal, toggleConfirmationModal] = useModali()
  const [selectedOption, handleSelectedOption] = useState("")
  const [modalOptions, toggleModal] = useModal()
  const [footerBanner, toggleFooterBanner] = useState(false)
  const [isScroll, setIsScroll] = useState(false)
  const [existingOrgModal, toggleExistingOrgModal] = useModali();
  useLayoutEffect(() => {
    import("scrollreveal").then(({ default: ScrollReveal }) => {
      const sr = ScrollReveal()
      sr.reveal(".hero__content", {
        delay: 150,
        ...bottomConfig,
      })
      sr.reveal(".hero__image", {
        delay: 450,
        ...bottomConfig,
      })

      // sr.reveal(".card", cardConfig)
      // sr.reveal(".features", featuresConfig)
      // sr.reveal(".business-wrap", featuresConfig)
      // sr.reveal(".business", bottomConfig)
      // sr.reveal(".features > .title", bottomConfig)
      // sr.reveal(".content-wrap", bottomConfig)
      // // sr.reveal(".cashflow > .header", bottomConfig)
      // sr.reveal(".join", joinConfig)
      // sr.reveal(".signup-step", bottomConfig)

      sr.reveal(".list>li", priceConfig)
      sr.reveal(".about", aboutConfig)
      sr.reveal(".logos-wrapper > .logo", bottomConfig)
      sr.reveal(".security  .plus", bottomConfig)
      sr.reveal(".security  p.mx-auto", bottomConfig)
      sr.reveal(".trusts  .small-title", bottomConfig)
      // sr.reveal(".box-wrapper> .box", bottomConfig)
      sr.reveal(".footer__top__inner >h3", bottomConfig)
      sr.reveal(".getstart-footer", {
        delay: 250,
        ...bottomConfig,
      })
      sr.reveal(".footer-menu", {
        delay: 400,
        ...bottomConfig,
      })
      sr.reveal(".footer__mobile-logo", bottomConfig)
      sr.reveal(".social-network", bottomConfig)
    })
    if (typeof window !== "undefined") {
      // when page load scroll to the hash anchor
      window.location.hash = window.decodeURIComponent(window.location.hash)
      const scrollToAnchor = () => {
        const hashParts = window.location.hash.split("#")
        // console.log("hashParts", hashParts)
        if (hashParts.length >= 2) {
          const hash = hashParts.slice(-1)[0]
          document.querySelector(`#${hash}`).scrollIntoView()
        }
      }
      scrollToAnchor()
      window.onhashchange = scrollToAnchor
    }
  }, [])

  useEffect(() => {
    // openFooterBanner();
  }, [])

  const openFooterBanner = () => {
    if (!cookies.mainPageFooterBanner) {
      ReactGA.event({
        category: "FOOTER-BANNER",
        action: "Open Main Page Footer Banner",
      })
      toggleFooterBanner(!footerBanner)
    }
  }

  const registerNowClick = () => {
    ReactGA.event({
      category: "FOOTER-BANNER",
      action: "Click on Register Now",
    })
    window.open("https://go.luca.plus/sme-webinar", "_blank")
  }

  const CloseFooterBanner = () => {
    ReactGA.event({
      category: "FOOTER-BANNER",
      action: "Close Main Page Footer Banner",
    })
    setCookie("mainPageFooterBanner", true)
    toggleFooterBanner(!footerBanner)
  }

  const myobClick = () => {
    ReactGA.event({
      category: "CTA-BUTTON",
      action: "Click Main Page MYOB cta Button",
    })
  }

  const qboClick = () => {
    ReactGA.event({
      category: "CTA-BUTTON",
      action: "Click Main Page QBO cta Button",
    })
  }


  const clickOfferBtn = () => {
    ReactGA.event({
      category: "Main page_30 day_Widget",
      action: "Main page_30 day_Widget",
    })
  }

  const lucaplusClick = () => {
    ReactGA.event({
      category: "Main page_step2_lucaplusexperience_anchor",
      action: "Main page_step2_lucaplusexperience_anchor",
    })
  }

  const checkOutPlanClick = () => {
    ReactGA.event({
      category: "Main page_check_out_our_plan",
      action: "Main page_check_out_our_plan",
    })
  }

  const getPersonalisedDemoClick = () => {
    ReactGA.event({
      category: "Main page_get_personalised_demo_today",
      action: "Main page_get_personalised_demo_today",
    })
  }

  if (typeof window !== "undefined") {
    window.onscroll = function() {
      if (window.scrollY > 0) {
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    };
  }

  let fansCard = []
  for (let i = 0; i < fans.length; i++) {
    fansCard.push(<div className="flex justify-content-center px-0 sm:px-8" key={i}>
      <div className="flex flex-col justify-center bg-white card card-border overflow-hidden  mx-0 sm:mx-3 z-20 relative mb-10 sm:mb-20 px-0 sm:px-5 md:px-10">
        <div className="flex items-center">
          <img src={starGroups} className="start-grp"/>
          <span className="name number ml-2">5.0</span>
        </div>
        <div className="paragraph text-neutral-3 text-left">
          {fans[i].content}
        </div>
        <div className="flex sm:mt-8 sm:items-baseline items-center">
          <div className={`image mr-3`}>
            <img
              src={fans[i].image}
              alt={fans[i].name}
              className="rounded-full object-cover object-top"
            />
          </div>
          <div className="flex justify-center text-left flex-col">
            <div className="text-base font-medium">
              {fans[i].name}
            </div>
            <div className="flex text-neutral-3 text-sm title">
              {fans[i].designation}
            </div>
          </div>
        </div>
      </div>
    </div>)
  }

  return (
    <Layout toggleExistingOrgModal={toggleExistingOrgModal} page="Main" freeImg={true} newFooter={true} simple={true} className={`${isScroll && "frosted-glass"}`}>
      <SEO title="LUCA Plus - Fintech platform for better business cash flow" description={"Get paid on time and release payment stress for smarter and faster business. LUCA Plus is an all-in-one cash flow and business transaction automation solution that empowers small to medium-sized businesses to thrive by achieving financial confidence, time and money savings"} />
      <Hero toggleExistingOrgModal={toggleExistingOrgModal} toggleContactModal={toggleContactModal} clickOfferBtn={clickOfferBtn} />

      {/*<div className="container">*/}
        {/*<div className="features">*/}
          {/*<div className="title">*/}
          {/*  Quick Features & Benefits*/}
          {/*  <img src={featureTopLineImage} className="absolute top-0" />*/}
          {/*</div>*/}
          {/*<div>*/}

            {/*<div className="flex justify-between sm:flex-row flex-col">*/}
            {/*  <div className="box">*/}
            {/*    <div className="feat-sec-img">*/}
            {/*      <div className="icon-bg"><img src={iconBGImage} id="icon-bg" /></div>*/}
            {/*      <img className="f1-img" src={f1Image} />*/}
            {/*      <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">*/}
            {/*        Save Time & Cost*/}
            {/*      </div>*/}
            {/*      <div className="text-neutral-3 text-16 sm:text-15">*/}
            {/*        Save up to 70% of your time via automated billing entry. Choose your preferred payment method to*/}
            {/*        save more money.*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="box sm:mt-0 mt-8">*/}
            {/*    <div className="feat-sec-img">*/}
            {/*      <div className="icon-bg" id="icon-bg"><img src={iconBGImage} /></div>*/}
            {/*      <img className="f1-img" src={f2Image} />*/}
            {/*      <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">*/}
            {/*        Get Paid Faster*/}
            {/*      </div>*/}
            {/*      <div className="text-neutral-3 text-16 sm:text-15 paragraph">*/}
            {/*        Cut down the debtor days with early payment discount, installments or multi-digital payment methods*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="box sm:mt-0 mt-8">*/}
            {/*    <div className="feat-sec-img">*/}
            {/*      <div className="icon-bg" id="icon-bg"><img src={iconBGImage} /></div>*/}
            {/*      <img className="f1-img" src={f3Image} />*/}
            {/*      <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">*/}
            {/*        Better Cash Flow*/}
            {/*      </div>*/}
            {/*      <div className="text-neutral-3 text-16 sm:text-15 paragraph">*/}
            {/*        Let us pay the bills for your business while you pay us back in 4 installments with LUCA Pay B2B Buy*/}
            {/*        Now Pay Later*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="box sm:mt-0 mt-8">*/}
            {/*    <div className="feat-sec-img">*/}
            {/*      <div className="icon-bg" id="icon-bg"><img src={iconBGImage} /></div>*/}
            {/*      <img className="f1-img" src={f4Image} />*/}
            {/*      <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">*/}
            {/*        Building Connections*/}
            {/*      </div>*/}
            {/*      <div className="text-neutral-3 text-16 sm:text-15 paragraph">*/}
            {/*        Thanks to the e-invoicing transaction network, your clients & suppliers can enjoy savings & access*/}
            {/*        to bundle deals*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          {/*</div>*/}

          {/*<div className="business-wrap">*/}
            {/*<div className="title sm:pt-16 pt-0">*/}
            {/*  What Businesses are suffering from*/}
            {/*  <img src={titleBottomVectorImage} className="mt-2 sm:block hidden" />*/}
            {/*  <img src={titleBottomVectorMobImage} className="mt-2 sm:hidden w-full" />*/}
            {/*</div>*/}
            {/*<div className="flex sm:justify-between justify-content-center sm:flex-row flex-col business ">*/}
            {/*<div className="box">*/}
            {/*  <div className="header">*/}
            {/*    89%*/}
            {/*    <img src={headerRightCornerImage} className="absolute img1" />*/}
            {/*  </div>*/}
            {/*  <div className="text-neutral-3 text-center text-15 text">*/}
            {/*    businesses are still wasting time with manual bookkeeping. Is your business doing the same?*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="box">*/}
            {/*  <div className="header">*/}
            {/*    $21.69*/}
            {/*    <img src={headerRightCornerImage} className="absolute img2" />*/}
            {/*  </div>*/}
            {/*  <div className="text-neutral-3 text-center text-15 text">*/}
            {/*    cost per invoice without LUCA Plus. Still wasting money on paper?*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="box">*/}
            {/*  <div className="header">*/}
            {/*    10.8 Days*/}
            {/*    <img src={headerRightCornerImage} className="absolute img3" />*/}
            {/*  </div>*/}
            {/*  <div className="text-neutral-3 text-center text-15 text px-2">*/}
            {/*    more days are added on average for late payments. Paid even longer due to COVID-19?*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="box">*/}
            {/*  <div className="header">*/}
            {/*    $128M*/}
            {/*    <img src={headerRightCornerImage} className="absolute img4" />*/}
            {/*  </div>*/}
            {/*  <div className="text-neutral-3 text-center text-15 text">*/}
            {/*    total losses of Australian businesses from invoice scams. Is your process guaranteed secure?*/}
            {/*  </div>*/}
            {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
        {/*</div>*/}
      {/*</div>*/}

      {/*<div className="cashflow">*/}
        {/*<div className="skewed-bg" />*/}
        {/*<div className="container">*/}
        {/*  <div className="header">*/}
        {/*    Cashflow management & payment solution on e-invoicing transaction network*/}
        {/*  </div>*/}
        {/*  <div className="title">*/}
        {/*    <div className={`t1 ${activeTab === "1" && "active"}`} onClick={() => setActiveTab("1")}>*/}
        {/*      No Accounting Software?*/}
        {/*    </div>*/}
        {/*    <div className="border-right" />*/}
        {/*    <div className={`t2 ${activeTab === "2" && "active"}`} onClick={() => setActiveTab("2")}>*/}
        {/*      With Existing Accounting Software?*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="flex items-center content-wrap sm:flex-row flex-col">*/}
        {/*    <div className="left">*/}
        {/*      <img src={leftImage} />*/}
        {/*    </div>*/}
        {/*    <div className="right">*/}
        {/*      <div className="content">*/}
        {/*        <div className="text-16 font-semibold">*/}
        {/*          {activeTab === "1" ? "Are you spending endless hours creating and chasing invoices on spreadsheets?" : "Are you having challenges with your business cash flow and back office efficiency?"}*/}
        {/*        </div>*/}
        {/*        <div className="text-neutral-3 text-15 mt-4">*/}
        {/*          {activeTab === "1" ? "LUCA Plus eliminates accounting headaches with simple tools to help manage invoices and bills." : "LUCA Plus eliminates accounting and invoicing headaches with simple tools."}*/}
        {/*          {activeTab === "1" ?*/}
        {/*            <ul className="mt-5 ml-8">*/}
        {/*              <li>*/}
        {/*                Cloud-based technology saves you up to 30 hours a month of manual back-office work.*/}
        {/*              </li>*/}
        {/*              <li className="mt-2">*/}
        {/*                Record expenses and pay bills to suppliers.*/}
        {/*              </li>*/}
        {/*            </ul>*/}
        {/*            : <div className="mt-5">*/}
        {/*              No need to change your invoicing habits with your existing accounting tool. Instead, LUCA Plus*/}
        {/*              add-on features automate your bookkeeping , account receivable, account payable process therefore*/}
        {/*              improving your cash flow.*/}
        {/*            </div>}*/}
        {/*        </div>*/}
        {/*        <a className={`flex text-18 mt-5 sm:pl-0 ${activeTab === "1" && "pl-6"}`} href="/pricing">*/}
        {/*          Get Started*/}
        {/*          <img src={arrowIcon} className="ml-1" />*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      {/*</div>*/}

      <div className="join">
        <img src={leftVectorIcon} className="left-vector sm:block hidden" />
        <div className="container">
          <div className="header">
            <img src={starIcon} className="mr-3" />
            <span className="sm:block hidden">Why you’ll love LUCA Plus</span>
            <span className="sm:hidden">Why LUCA Plus</span>
            <img src={starIcon} className="ml-3" />
          </div>
          <div className="flex items-center justify-content-center content-wrap sm:flex-row flex-col">
            <div className="left">
              <img src={joinLeftImage1} />
            </div>
            <div className="right">
              <div className="content">
                <div className="font-semibold title">
                  Simple Credit Applications in Minutes
                </div>
                <div className="text-15 text-neutral-3 my-4">
                  We offer a tailor-designed digital process for small businesses, providing quick access to credit for supplier payments with instant credit limit approval.
                </div>
                <Link to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}>
                <div
                  className="get-started-btn cursor-pointer sm:mt-0 mt-3 focus:outline-none flex justify-center items-center relative"
                >
                  Get Started
                  <img src={btnVectorImage} className="arrow" alt="right arrow"/>
                  <img src={btnVectorArrowImage} className="absolute vector-arrow" alt="right arrow"/>
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-content-center content-wrap sm:my-20 my-16 sm:py-5 sm:flex-row flex-col flex-col-reverse">
            <div className="left text-left s2">
              <div className="content">
                <div className="font-semibold title">
                  Seamless payment processing.
                </div>
                <div className="text-16 text-neutral-3 my-4">
                  Streamline your cash flow management and save time by syncing supplier invoices from your accounting software.
                </div>
                <Link to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}>
                <a
                  className="get-started-btn cursor-pointer sm:mt-0 mt-3 focus:outline-none flex justify-center items-center relative"
                >
                  Get Started
                  <img src={btnVectorImage} className="arrow" alt="right arrow"/>
                  <img src={btnVectorArrowImage} className="absolute vector-arrow" alt="right arrow"/>
                </a>
                </Link>
              </div>
            </div>
            <div className="right">
                <img src={joinRightImage1} />
            </div>
          </div>
          <div className="flex items-center justify-content-center content-wrap sm:flex-row flex-col">
            <div className="left s3">
              <img src={joinLeftImage2} />
            </div>
            <div className="right">
              <div className="content">
                <div className="font-semibold title">
                  Transparent fees and flexible credit terms.
                </div>
                <div className="text-15 text-neutral-3 my-4">
                  We ensure transparent fees and flexible credit terms. Say goodbye to hidden charges; ease your cash flow by repaying us in monthly instalments.
                </div>
                <Link to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}>
                <a
                  className="get-started-btn cursor-pointer sm:mt-0 mt-3 focus:outline-none flex justify-center items-center relative"
                >
                  Get Started
                  <img src={btnVectorImage} className="arrow" alt="right arrow"/>
                  <img src={btnVectorArrowImage} className="absolute vector-arrow" alt="right arrow"/>
                </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative signup-step-wrap">
        <div className="bg" />
        <div className="container">
        <div className="signup-step">
          <div className="sm:w-1/2 w-full">
            <img src={rightVector} className="right-vector sm:block hidden" />
            <div className="header">
              Sign up in 3 simple steps
            </div>
            <div>
              <img src={signUpStep} className="step-img sm:block hidden" />
            </div>
          </div>
          <div>
            <div className="card">
              <img src={stepHand} className="sm:block hidden sm:mr-10 mb-2 h-full" />
              <div className="flex flex-col">
                <div className='flex flex-col text-left'>
                  <div className="title">
                    STEP 1
                  </div>
                  <div className="text-neutral-3 text-15 mt-2 text">
                    Sign up to provide basic information and link your accounting software to our platform.
                  </div>
                </div>
                <div className='flex flex-col text-left sm:mt-16 mt-8 pb-13'>
                  <div className="title">
                    STEP 2
                  </div>
                  <div className="text-neutral-3 text-15 mt-2 text">
                    Complete a quick and simple business verification process - <b>approved by our system within hours.</b>
                  </div>
                </div>
                <div className='flex flex-col text-left sm:mt-13 mt-6 mb-12'>
                  <div className="title">
                    STEP 3
                  </div>
                  <div className="text-neutral-3 text-15 mt-2 text">
                    Return to our platform and upload the supplier invoices you want to pay up to the value of your credit limit.
                  </div>
                </div>
                <Link
                  to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}
                  className="bg-mind text-white flex justify-content-center items-center start-btn"
                >
                  Ready to start
                  <img src={rightWhiteArrow} className="ml-2"/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/*<div className="value">*/}
      {/*  /!*<img src={valueLeftVector} className="left-vector sm:block hidden" />*!/*/}
      {/*  <div className="">*/}
      {/*    <span className="review">Reviews</span>*/}
      {/*    <div className="header">*/}
      {/*      Our valued subscribers want you to know what they think about LUCA Plus*/}
      {/*    </div>*/}
      {/*    <div className="text-neutral-3 text-16 sub-text">*/}
      {/*      Hear what our partners have to say*/}
      {/*    </div>*/}
      {/*    <div className="tech-slideshow sm:block hidden">*/}
      {/*      <div className="mover-1"/>*/}
      {/*      <div className="mover-2"/>*/}
      {/*    </div>*/}
      {/*    <div className="card-wrap ">*/}
      {/*      <Carousel className="sm:hidden">*/}
      {/*        {fansCard}*/}
      {/*      </Carousel>*/}
      {/*    </div>*/}
      {/*  /!*  <div className="flex">*!/*/}
      {/*  /!* {fans.map(fan => <div className="box">*!/*/}
      {/*  /!*  <div className="flex items-center">*!/*/}
      {/*  /!*    <img src={starGroups} />*!/*/}
      {/*  /!*    <span className="name number ml-2">5.0</span>*!/*/}
      {/*  /!*  </div>*!/*/}
      {/*  /!*  <div className="paragraph">*!/*/}
      {/*  /!*    {fan.content}*!/*/}
      {/*  /!*  </div>*!/*/}
      {/*  /!*  <div className="flex sm:mt-8 items-center details">*!/*/}
      {/*  /!*    <div className={`image mr-3`}>*!/*/}
      {/*  /!*      <img*!/*/}
      {/*  /!*        src={fan.image}*!/*/}
      {/*  /!*        alt="f1Image"*!/*/}
      {/*  /!*          className="rounded-full object-cover object-top"*!/*/}
      {/*  /!*        />*!/*/}
      {/*  /!*      </div>*!/*/}
      {/*  /!*      <div className="flex justify-center text-left flex-col">*!/*/}
      {/*  /!*        <div className="text-base font-medium name">*!/*/}
      {/*  /!*          {fan.name}*!/*/}
      {/*  /!*        </div>*!/*/}
      {/*  /!*        <div className="flex text-neutral-3 text-sm title">*!/*/}
      {/*  /!*          {fan.designation}*!/*/}
      {/*  /!*        </div>*!/*/}
      {/*  /!*      </div>*!/*/}
      {/*  /!*    </div>*!/*/}
      {/*  /!*  </div>)}*!/*/}
      {/*  /!*</div>*!/*/}
      {/*/!*    </div>*!/*/}
      {/*  </div>*/}
      {/*</div>*/}

        <div className="trusts">
        <div className="container">
          <div className="header">
            Partners that have seen results and trust LUCA Plus
          </div>
          <div className="main-img sm:block hidden">
            <img src={trustImage} />
          </div>
          <div className="main-img-mob sm:hidden">
            <div className="mover-1"/>
            <div className="mover-2"/>
          </div>
        </div>
      </div>


      <Modali.Modal {...contactModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/kJznAHK4wVM?autoplay=1&enable_js=1"
            title="Introduction to LUCA Plus"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modali.Modal>
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
            title="Introduction to LUCA Plus"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modali.Modal>

      <Modali.Modal {...videoModal1}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/kJznAHK4wVM?autoplay=1&enable_js=1"
            title="Introduction to LUCA Plus"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modali.Modal>

      <Modali.Modal {...confirmationModal} className=" error-modal">
        <div className="pt-12 pop-up">
          <div className="pop-up-inner mx-auto">
            <div className=" text-neutral-3 mt-4">
              Are you an existing XERO, MYOB or QuickBooks user?
            </div>
            <div className="mt-8 mb-20">
              <Link
                to={`/sign-up/`}
                state={{ isTrial: true, plan: "partner", price: 0 }}
              >
                <input
                  className="option cursor-pointer"
                  type="radio"
                  value="yes"
                  name="option"
                  checked={selectedOption === "yes"}
                  onClick={() => handleSelectedOption("yes")}
                />
                <label className="ml-2" htmlFor="option-1">
                  Yes
                </label>
              </Link>
              <Link
                to={`/signup/`}
                className="ml-12"
              >
                <input
                  className="cursor-pointer"
                  type="radio"
                  value="no"
                  name="option"
                  checked={selectedOption === "no"}
                  onClick={() => handleSelectedOption("no")}
                />
                <label className="ml-2" htmlFor="option-1">
                  No
                </label>
              </Link>
            </div>
          </div>
        </div>
      </Modali.Modal>
      <Modali.Modal {...existingOrgModal} className=" error-modal exist-org-modal" >
        <div className="pt-4">
          <div className="pop-up-inner mx-auto">
            <div className="mt-8 mb-12 flex flex-col">
              <Link
                to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}
                state={{ isTrial: true, fromHomePage: true }}
                className="popup-box cursor-pointer"
              >
                <input
                  className="option cursor-pointer"
                  type="radio"
                  value="xero"
                  name="option"
                  checked={selectedOption === "xero"}
                  onClick={() => handleSelectedOption("xero")}
                />
                <label className="ml-5 w-10/12cursor-pointer" htmlFor="option-1">
                  I use Xero, MYOB, Quickbooks
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner&lucapay=true&fromHomePage=true`}
                state={{ isTrial: true, fromHomePage: true }}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5 cursor-pointer" htmlFor="option-1">
                  I use other accounting software
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner&lucapay=true&lucapayLite=true&fromHomePage=true`}
                state={{ isTrial: true }}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5 cursor-pointer" htmlFor="option-1">
                  I don‘t have any accounting software
                </label>
              </Link>
            </div>
          </div>
        </div>
      </Modali.Modal>

      <Popup.Modal {...modalOptions}>
        <GetCode1 close={toggleModal} />
      </Popup.Modal>
    </Layout>
  )
}

export default IndexPage


const fans = [
  {content: `“It’s amazing how LUCA Plus e-Invoicing system can help out Fintech start-ups like us. It makes it so easy for us to manage bills sent from freelancers and contractors. Bookkeeping can be done in seconds.”`,
    image: fan1Image, name: "Angus Muffatti", designation: "CEO at Agtuary" },
  {content: `"Thank goodness for LUCA Plus - it has literally saved my life! Since subscribing to LUCA Plus my business is far more organised - and bookkeeping virtually looks after itself. Now I can focus on growing my business, and I finally have more time to spend with my daughter."`,
    image: fan2Image, name: "Ariel Tsao", designation: "CEO at My VIP Cleaning Company" },
  {content: `“We have built very good relationship with our suppliers because they get paid faster due to LUCA Plus. It also reduces bookkeeping errors for our business and saves us time processing invoices and bills."`,
    image: fan3Image, name: "Ariel Tsao", designation: "CEO at My VIP Cleaning Company" },
  {content: `“Our construction business engages with many project companies, tradies and subcontractors. LUCA Plus certainly simplifies the whole invoicing and billing management systems for us. We can save a lot of time to focus more on developing new businesses.”`,
    image: fan4Image, name: "Steve Shi", designation: "Senior PM at Cornerstone Building & Construction" },
  {content: `“I am really bad at managing my invoices a
nd bills for my freelancer business, but LUCA Plus really helps me to focus on growing my business and saves me so much time dealing with painful bookkeeping. I really love it.”`,
    image: fan5Image, name: "Yanina", designation: "Artist & Freelancer" },
  ]