import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import { Link } from "gatsby"
import ReactGA from "react-ga"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import HeroImage from "../images/hero-image.svg"
import HeroNewImage from "../images/hero-image-new.png"
import headerBackgroundImg from "../images/header-background.svg"
import headerBackgroundNewImg from "../images/hero/header-background-new.svg"
import heroLeftImg1 from "../images/hero/hero-left1.svg"
import heroLeftImg2 from "../images/hero/hero-left2.svg"
import heroRightImg1 from "../images/hero/hero-right1.svg"
import heroRightImg2 from "../images/hero/hero-right2.png"
import heroImage from "../images/hero/hero-image.svg"

import { getAllUrlParams } from "../utils"
import specialOfferImg from "../images/special-offer.svg"
import specialOfferBtnImg from "../images/special-offer-btn.svg"
import watchHowItWorksBtnImg from "../images/watch-how-it-works-btn.svg"
import heroLeftVectorImg from "../images/solo/hero-left-curve.svg"
import heroRightImg from "../images/solo/hero-right.png"
import playIcon from "../images/solo/play_icon.svg"
import heroRightVectorImg from "../images/solo/hero-right-curve.svg"
import Modali, { useModali } from "./modal"
// import { useController } from "react-scroll-parallax"
import footerLeftVectorImg from "../images/new-pricing/top-left-vector.png"
import footerRightVectorImg from "../images/new-pricing/top-right-vector.png"

export default function Hero({ toggleContactModal, clickOfferBtn, toggleExistingOrgModal }) {
  const [emailValue, setEmailValue] = useState("")
  const [promoCode, setPromocode] = useState("")
  const [videoModal, toggleVideoModal] = useModali()
  const scrollRef = useRef(null)
  useEffect(() => {
    setPromocode(getAllUrlParams().promo_code || "")
  })


  const handleOnChange = (e) => {
    setEmailValue(e.target.value)
  }

  const watchHowItWorks = (btn) => {
    ReactGA.event({
      category: `Main page_watch_how_it_works${btn}`,
      action: `Main page_watch_how_it_works${btn}`,
    })
    toggleContactModal()
  }
  let scrolling = false;
  const onScroll = (event) => {
    console.log('in this method----', event);
    event.preventDefault();
    event.stopPropagation();
    // console.log('in this method deltaY----', event.deltaY);
    // if (event.target === event.currentTarget) {
    //   console.log("scrolling");
    // }
    // if(!scrolling) {
    //   scrolling = true
    //   if (event.deltaY < 0) {
    //     setSideImg1({
    //       transform: `translate3d(${sideImg1Value.translateValue1 + 18}%, ${sideImg1Value.translateValue2 + 10}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg,0deg)`,
    //     })
    //     setSideImg1Value({
    //       translateValue1: sideImg1Value.translateValue1 + 18,
    //       translateValue2: sideImg1Value.translateValue2 + 10,
    //     })
    //     scrolling =  false
    //     console.log("topppppppppppppppppppppp")
    //   } else if (event.deltaY > 0) {
    //     setSideImg1({
    //       transform: `translate3d(6.5%, 5%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg,0deg)`,
    //     })
    //     setSideImg1Value({
    //       translateValue1: sideImg1Value.translateValue1 - 18,
    //       translateValue2: sideImg1Value.translateValue2 - 10,
    //     })
    //     scrolling =  false
    //     console.log("bottommmmmmmmmmmmmmmmmmmmmmm")
    //   }
    // }
  }

  // const ParallaxCache = () => {
  //   const { parallaxController } = useController();
  //
  //   useLayoutEffect(() => {
  //     const handler = () => parallaxController.update();
  //     window.addEventListener('load', handler);
  //     return () => window.removeEventListener('load', handler);
  //   }, [parallaxController]);
  //
  //   return null;
  // };


  return (<div className="hero section flex-col">
    {/*<ParticlesWrapper />*/}
    {/*<ParallaxCache />*/}
    <div className="container" ref={scrollRef} >
      <div className="title font-bold text-brand relative">
        Simple cash flow - the smarter way to make your business{" "}
        <span className="header-bg sm:block hidden">
          <img src={headerBackgroundNewImg} />
        </span>
        grow
      </div>
      <div className="text-neutral-3 text-20 sub-text">
        At LUCA Plus, we provide fast, simple and transparent access to short-term B2B payment credit solutions.
      </div>
      <div className={"hero-btn mt-5"}>
        <Link  to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}>
          Get started
        </Link>
        <a href="/about/" className="focus:outline-none ml-5">
          Who are we
        </a>
      </div>
      <div className="video-img flex justify-content-center">
        <div className="relative">
          <div>
            <img src={heroImage} className="hero-right-main-img" />
          </div>
          {/*<Parallax onLoad={({parallaxController}) => parallaxController.update()}>*/}
            {/*<Parallax className="side-img1" x={[-70, 70]}  tagOuter="figure" >*/}
            {/*<div className="side-img1">*/}
            {/*  <img src={heroLeftImg1} />*/}
            {/*</div>*/}
          {/*</Parallax>*/}
          {/*  <Parallax className="side-img2" x={[-70, 70]} tagOuter="figure" >*/}
          {/*  <div className="side-img2">*/}
          {/*    <img src={heroLeftImg2} />*/}
          {/*  </div>*/}
            {/*</Parallax>*/}
            {/*<img src={HeroNewImage} className="hero-right-main-img" />*/}
            {/*<Parallax className="side-img3" x={[70, -70]} tagOuter="figure" >*/}
            {/*<div className="side-img3">*/}
            {/*  <img src={heroRightImg1} />*/}
            {/*</div>*/}
            {/*</Parallax>*/}
            {/*<Parallax className="side-img4" x={[70, -70]} tagOuter="figure" >*/}
            {/*<div className="side-img4">*/}
            {/*  <img src={heroRightImg2} />*/}
            {/*</div>*/}
            {/*</Parallax>*/}
          {/*</ParallaxProvider>*/}
        </div>
        {/*<div className="play-icon cursor-pointer" onClick={toggleVideoModal}><img src={playIcon} /></div>*/}
      </div>
    </div>

    <div className="flex items-center justify-content-center mt-3 sm:hidden">
      <div>
        <img src={footerLeftVectorImg} className="sm:w-7/12 w-4/6 ml-auto" />
      </div>
      <Link  to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}>
      <div
        className="signup-btn focus:outline-none flex justify-center items-center cursor-pointer"
      >
        Sign Up
      </div>
      </Link>
      <div>
        <img src={footerRightVectorImg} className="sm:w-7/12 w-4/6 mr-auto" />
      </div>
    </div>
    <div className="flex items-center justify-content-center mt-3 sm:hidden">
      <div>
        <img src={footerLeftVectorImg} className="sm:w-7/12 w-4/6 ml-auto" />
      </div>
      <a
        className="signup-btn focus:outline-none flex justify-center items-center cursor-pointer"
        href={`${process.env.GATSBY_APP_URL}/login`}
      >
        Log In
      </a>
      <div>
        <img src={footerRightVectorImg} className="sm:w-7/12 w-4/6 mr-auto" />
      </div>
    </div>

    {/*<div className="container">*/}
    {/*  <div className="hero__inner ">*/}
    {/*    <div className="hero__content load-hidden">*/}
    {/*      <div className="text-center sm:text-left">*/}
    {/*        <div className="relative special-offer-btn sm:block flex justify-center">*/}
    {/*          <Link*/}
    {/*            to={`/pricing`}*/}
    {/*            state={{ isPromoCode: true }}*/}
    {/*            onClick={clickOfferBtn}*/}
    {/*            className="btn-link"*/}
    {/*          >*/}
    {/*            <img src={specialOfferBtnImg} className="cursor-pointer" />*/}
    {/*          </Link>*/}
    {/*          <div className="offer-img"><img src={specialOfferImg} /></div>*/}
    {/*        </div>*/}

    {/*        <h2 className="hero-title mb-4 text-po">All you need for better cash flow & back-office efficiency</h2>*/}

    {/*        <div className="flex items-baseline sm:justify-start justify-center">*/}
    {/*          <button className="play-btn focus:outline-none " onClick={() => watchHowItWorks("1")}>Watch how it works*/}
    {/*            <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none"*/}
    {/*                 xmlns="http://www.w3.org/2000/svg">*/}
    {/*              <path*/}
    {/*                d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z"*/}
    {/*                fill="white" />*/}
    {/*            </svg>*/}
    {/*          </button>*/}
    {/*        </div>*/}
    {/*      </div>*/}


    {/*      <p className="hero-paragraph">*/}
    {/*        We simplify & automate your finance operations so you’re free to do what matters most*/}
    {/*      </p>*/}
    {/*      <div className="hero-cta">*/}
    {/*        <div className="hero-cta__email">*/}
    {/*          <input*/}
    {/*            type="email"*/}
    {/*            className="email-control email--hero"*/}
    {/*            placeholder="  Your email"*/}
    {/*            name="email"*/}
    {/*            value={emailValue}*/}

    {/*            onChange={handleOnChange}*/}
    {/*          />*/}
    {/*        </div>*/}

    {/*        <div className="hero-cta__btn">*/}
    {/*          <Link*/}
    {/*            to={`/einvoicing-australia${emailValue && `?email=${emailValue}`}${promoCode && `&promo_code=${promoCode || ""}`}`}*/}
    {/*            state={{ isPromoCode: true }}*/}
    {/*            className="nav-btn hover:bg-secondary nav-btn--filled hero-btn"*/}
    {/*          >*/}
    {/*            GET STARTED*/}
    {/*          </Link>*/}
    {/*        </div>*/}
    {/*      </div>*/}


    {/*    </div>*/}
    {/*    <div className="hero__image load-hidden">*/}
    {/*      <img src={watchHowItWorksBtnImg} className="btn" onClick={watchHowItWorks} />*/}
    {/*      <img src={HeroImage} alt="hero" />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
    <Modali.Modal {...videoModal}>
      <div className="hero-video">
        <iframe
          src="https://www.youtube.com/embed/lmhxVS47KgM?autoplay=1&enable_js=1"
          title="Introduction to LUCA+"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          allow="autoplay"
        />
      </div>
    </Modali.Modal>
  </div>)
}
