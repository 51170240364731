export const bottomConfig = {

  duration: 1000,
  distance: '60px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  origin: 'bottom',
  interval: 150,
  // reset: true
}



export const cardConfig = {
  duration: 600,
  distance: '60px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  interval: 150,
  origin: 'left',
  // viewFactor: 0.1,
  // reset: true
}
export const featuresConfig = {
  duration: 600,
  distance: '50px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  interval: 500,
  origin: 'bottom',
  viewFactor: 0.1,
  reset: true
}

export const cashflowConfig = {
  duration: 600,
  distance: '60px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  interval: 100,
  origin: 'bottom',
  viewFactor: 0.2,
  reset: true
}

export const joinConfig = {
  duration: 600,
  distance: '60px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  interval: 150,
  origin: 'bottom',
  viewFactor: 0.2,
  reset: true
}

export const partnersConfig = {
  duration: 600,
  distance: '80px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  interval: 100,
  origin: 'bottom',
  // viewFactor: 0.1,
  // reset: true
}

export const priceConfig = {
  duration: 600,
  distance: '40px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  interval: 100,
  origin: 'bottom',
  viewFactor: 0.2,
  // reset: true
}

export const aboutConfig = {
  duration: 600,
  distance: '60px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  interval: 150,
  origin: 'bottom',
  viewFactor: 0.2,
  reset: true
}


